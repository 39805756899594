import { Alert, AlertIcon, ChakraProvider, FormControl, FormLabel, Stack, Switch } from '@chakra-ui/react'
import React, { Component, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Button, Form, FormGroup, Input, Label } from 'reactstrap'
// import { apiUrl, appRootPathPrefix } from '../../config'
import { history } from '../../redux/history'
import { Loader } from '../common'
import { identityServices } from '../common/index'
import './style.css'
const defaultWorkingHours = [
	{
		day: 'Sunday',
		end: '17:00:00',
		key: 0,
		start: '08:00:00',
		isOpen: false,
	},
	{
		day: 'Monday',
		end: '17:00:00',
		key: 1,
		start: '08:00:00',
		isOpen: true,
	},
	{
		day: 'Tuesday',
		end: '17:00:00',
		key: 2,
		start: '08:00:00',
		isOpen: true,
	},
	{
		day: 'Wednesday',
		end: '17:00:00',
		key: 3,
		start: '08:00:00',
		isOpen: true,
	},
	{
		day: 'Thursday',
		end: '17:00:00',
		key: 4,
		start: '08:00:00',
		isOpen: true,
	},
	{
		day: 'Friday',
		end: '17:00:00',
		key: 5,
		start: '08:00:00',
		isOpen: true,
	},
	{
		day: 'Saturday',
		end: '17:00:00',
		key: 6,
		start: '08:00:00',
		isOpen: false,
	},
]
class BusinessHoursComponent1 extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			editMode: false,
			errorMsg: null,
			tenantId: null,
			formMeta: props && props.meta && props.meta.fields && props.meta.fields ? props.meta.fields : {},
			workingHours: defaultWorkingHours,
			formValues: { name: '', label: '', workingHours: [], status: '' },
			tenant: props && props.tenant,
		}
		this.handleChange = this.handleChange.bind(this)
		this.handleWorkingHoursChange = this.handleWorkingHoursChange.bind(this)
		this.onSubmit = this.onSubmit.bind(this)
	}
	async componentDidMount() {
		let props = this.props
		const { profile, t, tenant } = props
		// this.setState({ tenantId: profile.tenantUid || null, tenant })

		if (props && props.params && props.params.itemId) {
			// 	this.setState({ loading: true })
			await this.props.getOne({ filter: [{ name: 'id', value: props.params.itemId }], t })
		}
		// if (props && props.meta && props.meta.fields && props.meta.fields) {
		// 	this.setState({ formMeta: props.meta.fields })
		// }
	}

	componentWillReceiveProps(props) {
		const { entity } = props
		if (entity && entity.entityValues && typeof entity.entityValues === 'object' && Object.keys(entity.entityValues).length > 0) {
			let entityValue = entity.entityValues
			this.setState({ formValues: entityValue, loading: entity.loading, editMode: true, workingHours: entityValue.workingHours || this.state.workingHours })
			// if (entityValue.workingHours) {
			// 	this.setState({ workingHours: entityValue.workingHours })
			// }
		} else {
			this.setState({ editMode: false, workingHours: defaultWorkingHours, formValues: { name: '', label: '', workingHours: defaultWorkingHours, status: '' } })
		}
	}

	toCamelCase = str => {
		return str.replace(/^([A-Z])|\s(\w)/g, function (match, p1, p2, offset) {
			if (p2) return p2.toUpperCase()
			return p1.toLowerCase()
		})
	}
	typeModal(item) {
		const {
			meta: { categoriesMeta },
		} = this.props
		if (categoriesMeta && categoriesMeta[item]) return categoriesMeta[item]
	}

	handleWorkingHoursChange(name, item) {
		let x
		if (item && this.state[name]) {
			x = Object.keys(this.state[name]).some(k => {
				if (this.state[name][k] === item[k]) return false
				else return true
			})
			if (!x)
				x = Object.keys(item).some(k => {
					if (this.state[name][k] === item[k]) return false
					else return true
				})
		} else if (item || name) {
			x = true
		}
		if (x)
			this.setState({
				[name]: item,
			})
	}
	handleChange(event) {
		const { formValues } = this.state
		if (event.target.name === 'name') event.target.value = this.toCamelCase(event.target.value).trim()

		this.setState({
			formValues: {
				...formValues,
				[event.target.name]: event.target.value,
			},
		})
	}

	onSubmit(e) {
		e.preventDefault()
		let { t } = this.props
		const { formValues, workingHours } = this.state
		let value = { ...formValues, workingHours }
		if (value.name === '') value.name = this.toCamelCase(value.label).trim()

		this.setState({ loading: true })
		this.props.upsertItem({ entityValues: value, t })
		setTimeout(() => {
			this.setState({ loading: false })
		}, 300)
	}

	async delete() {
		const { formValues } = this.state
		this.setState({ loading: true })
		let { t } = this.props
		formValues.status = 'Deleted'
		let config = { apiUrl: '/Calendar', filter: `?$filter=businessHours eq '${formValues.name}'` }
		let checkExists = await this.handleFieldApi(config)
		if (checkExists && checkExists.length > 0 && checkExists[0].tenantId === this.state.tenantId) {
			this.setState({ errorMsg: t(`Cannot Delete "${formValues.label}" as this is tagged to "${checkExists[0].label}" Calendar`) })
		} else {
			this.props.upsertItem({ entityValues: formValues, t })
			this.setState({ errorMsg: null })
		}
		setTimeout(() => {
			this.setState({ loading: false })
		}, 300)
	}

	async handleFieldApi(config) {
		const { profile } = this.props
		const tenant = this.state.tenant || this.props.tenant
		let apiUrlCustom = profile.tenantUid !== undefined && profile.tenantUid !== null ? `${tenant.apiUrl}` : tenant.apiUrl
		if (config && config && config.apiUrl) {
			let endPoint = config.apiUrl.replace(/`/g, "'")
			const requestOptions = {
				method: 'GET',
				headers: { Pragma: 'no-cache', 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
				url: `${apiUrlCustom}${endPoint}${config.filter || ''}`,
			}

			let response = await identityServices.identityApi.request(requestOptions)
			if (response && response.status && response.status === 200) {
				if (response && response.data && response.data.rows) {
					response.data = response.data.rows
				}
				return response.data
			} else return false
		} else return false
	}

	back() {
		history.goBack()
	}
	render() {
		const { loading, formValues, formMeta, editMode, workingHours, errorMsg } = this.state
		const { t } = this.props
		return (
			<div className="animated fadeIn">
				<div className="main-section">
					<div className="main-inner">
						{loading && <Loader />}
						<div className="common-heading">
							<div className="row table-title">
								<div className="col-sm-6">
									<div className="heading">
										<h5>{t('Business Hours')}</h5>
									</div>
								</div>
								<div className="col-sm-6">
									<Button color="link" className="pull-right" onClick={e => history.goBack()}>
										{t('Back to List')}
									</Button>
								</div>
							</div>
						</div>
						<section>
							{errorMsg && <AlertBox status="error" title="Cannot Delete" msg={errorMsg} />}
							<Form onSubmit={this.onSubmit}>
								{formMeta &&
									Object.keys(formMeta).map((field, idx) => {
										let formField = formMeta[field]
										if (formField.create) {
											return (
												<FormGroup className="row" key={idx}>
													<div className="col-sm-3">
														<Label for="exampleEmail">{formField && formField.label ? t(formField.label) : ''}</Label>
													</div>
													<div className="col-sm-9">
														{formField && formField.type === 'select' && (
															<Input
																type={formField && formField.type ? formField.type : 'text'}
																name={formField && formField.name ? formField.name : 'formField'}
																value={formValues && formValues[formField.name]}
																className="col-sm-4"
																placeholder={t(formField && formField.placeholder ? formField.placeholder : '')}
																required={(formField && formField.required) || false}
																onChange={this.handleChange}
																disabled={formField && formField.readOnlyOnEdit && editMode}>
																<option value="">{t('--Select--')}</option>
																{formField &&
																	formField.options &&
																	Array.isArray(formField.options) &&
																	formField.options.map((elem, id) => (
																		<option key={id} value={elem.value}>
																			{t(elem.label)}
																		</option>
																	))}
															</Input>
														)}
														{formField && formField.type === 'text' && formField.borderRead && (
															<Input
																type={formField && formField.type ? formField.type : 'text'}
																name={formField && formField.name ? formField.name : 'formField'}
																value={formValues && formValues[formField.name]}
																className="col-sm-4"
																id={!window.location.pathname.includes('create') ? "borderStyle" : ""}
																placeholder={t(formField && formField.placeholder ? formField.placeholder : '')}
																required={(formField && formField.required) || false}
																onChange={this.handleChange}
																disabled={formField && formField.readOnlyOnEdit && editMode}
															/>
														)}
														{formField && formField.type === 'text' && !formField.borderRead && (
															<Input
																type={formField && formField.type ? formField.type : 'text'}
																name={formField && formField.name ? formField.name : 'formField'}
																value={formValues && formValues[formField.name]}
																className="col-sm-4"
																placeholder={t(formField && formField.placeholder ? formField.placeholder : '')}
																required={(formField && formField.required) || false}
																onChange={this.handleChange}
																disabled={formField && formField.readOnlyOnEdit && editMode}
															/>
														)}

														{formField && formField.type === 'jsonObj' && formField.name === 'workingHours' && (
															<TypeModal {...this.props} handleChange={this.handleWorkingHoursChange} meta={workingHours} config={formField} />
														)}
													</div>
												</FormGroup>
											)
										}
									})}
								<FormGroup>
									<div className="pull-right">
										{editMode && formValues.tenantId === this.state.tenantId && (
											<Button onClick={this.delete.bind(this, formValues.id)} style={{ cursor: 'pointer' }} color="danger">
												{t('Delete')}
											</Button>
										)}{' '}
										<Button color="primary">{editMode ? t('Update') : t('Create')}</Button>
									</div>
									<div className="clearfix"></div>
								</FormGroup>
							</Form>
						</section>
					</div>
				</div>
			</div>
		)
	}
}
export const BusinessHoursComponent = withTranslation()(BusinessHoursComponent1)

const TypeModal = props => {
	const { meta, config, handleChange } = props
	const [workingHours, setWorkingHours] = useState(meta)
	useEffect(() => {
		handleChange(config.name, workingHours)
	}, [config.name, handleChange, workingHours])
	useEffect(() => {
		setWorkingHours(meta)
	}, [meta])
	const onChange = (item, e) => {
		let name = e.target.name
		let value = name && name === 'isOpen' ? e.target.checked : e.target.value
		setWorkingHours(
			[...workingHours].map(object => {
				if (object.day === item.day) {
					return {
						...object,
						[name]: value,
					}
				} else return object
			})
		)
	}
	return (
		<div className="workingHours">
			<ChakraProvider>
				{workingHours &&
					Array.isArray(workingHours) &&
					workingHours.map((item, idx) => {
						return (
							<FormControl p={2} className="row" key={idx}>
								<div className="col-sm-2">
									<FormLabel htmlFor={`switch-${idx}`} mb="0">
										{props.t(item.day)}
									</FormLabel>
								</div>
								<div className="col-sm-1">
									<Switch id={`switch-${idx}`} isChecked={item.isOpen} value={item.isOpen} name="isOpen" onChange={onChange.bind(this, item)} />
								</div>
								<div className="col-sm-2">
									<FormLabel mb="0">{item.isOpen ? props.t('Open') : props.t('Closed')}</FormLabel>
								</div>
								{item.isOpen && (
									<div className="col-sm-3">
										<Input className="inputline" name="start" onChange={onChange.bind(this, item)} value={item.start || 0} type="time" />
									</div>
								)}
								{item.isOpen && (
									<div className="col-sm-3">
										<Input className="inputline" name="end" onChange={onChange.bind(this, item)} value={item.end || 0} type="time" />
									</div>
								)}
							</FormControl>
						)
					})}
			</ChakraProvider>
		</div>
	)
}

const AlertBox = props => {
	return (
		<Stack spacing={3}>
			<Alert status={props.status} variant="solid">
				<AlertIcon />
				{props.msg}
			</Alert>
			<br />
		</Stack>
	)
}
