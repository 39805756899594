/**
 * RU on all settings including whitelisting
 * This is in common as all apps would need it
 * Setting Key value pairs come from db of the app.
 */
import { BusinessHoursComponent } from '../appComponent'
import { Entity } from '../common/base/entity/entity'
import { collectionSchemas, entitySchema, methodSchemas } from './businessHours.schema'
import { ContentActions } from './ContentActions'
/**
 * Instructions:
 * 1. For simple usage steps:
 * a. fieldsMeta, actionsMeta, screens, sectionsMeta definition and creation of BaseEntity obj with these inputs
 * 2. For simple overrides of baseEntity
 * a. create an extended class of baseEntity and override its methods...
 * 3. For more customization
 * a. create a derived Service, derived actions and use them in derived BasedEntity class.
 * b. for component customization pass those components to the constuctor of derived BaseEntity class. SearchList component is the root component.
 *
 *
 */

class BusinessHoursEntity extends Entity {
	// override the following if needed.
	// derived actionObj
	// derived Service obj
	// getActionFns if you want to suppress the baseActionobj methods
}

const fieldsMeta = {
	name: {
		label: 'Name',
		type: 'text',
		placeholder: 'Name',
		name: 'name',
		borderRead: true,
		readOnlyOnEdit: true,
		create: true,
	}, //
	label: {
		label: 'Business Hours Label',
		required: true,
		type: 'text',
		name: 'label',
		placeholder: 'Business Hours Label',
		create: true,
	},
	workingHours: {
		label: 'Working Hours',
		required: true,
		type: 'jsonObj',
		name: 'workingHours',
		placeholder: 'Working Hours',
		create: true,
	},
	status: {
		label: 'Status',
		name: 'status',
		options: [
			{ label: 'Published', value: 'Published' },
			{ label: 'Draft', value: 'Draft' },
		],
		type: 'select',
		required: true,
		default: 'Published',
		create: true,
		// width:'10%',
	},
	createdBy: {
		label: 'Created By',
		name: 'createdBy',
		// readOnly: true,
		required: true,
		type: 'text',
	},
	createdAt: {
		label: 'Created At',
		name: 'createdAt',
		// readOnly: true,
		required: true,
		type: 'text',
	},
	updatedBy: {
		label: 'Last Edited By',
		name: 'updatedBy',
		// readOnly: true,
		required: true,
		type: 'text',
	},
	updatedAt: {
		label: 'Last Edited At',
		name: 'updatedAt',
		// readOnly: true,
		required: true,
		type: 'datetime-local',
	},
}

const actionsMeta = {
	doSomething: {
		type: 'button',
		label: 'Update',
		action: 'editItem',
		name: 'doSomething',
	},
	editMap: {
		label: 'Edit Map',
		name: 'editMap',
		action: 'showMap',
		condition: 'typeof value === "object"',
	},
	save: {
		label: 'Save',
		name: 'save',
		action: 'save',
	},
	cancel: {
		label: 'Cancel',
		name: 'cancel',
		action: 'cancelEdit',
	},
	update: {
		label: 'Update',
		name: 'update',
		action: 'upsertItem',
		classes: 'fa fa-pencil fa-lg ',
		type: 'span',
	},
	list: {
		label: 'List All',
		name: 'list',
		action: 'load',
	},
	search: {
		label: 'Search',
		name: 'search',
		action: 'search',
	},
	view: {
		label: 'View',
		name: 'view',
		action: 'getItem',
		classes: 'fa fa-eye fa-lg',
		type: 'span',
	},

	create: {
		label: 'Create',
		name: 'create',
		type: 'button',
		action: 'addNewItem',
	},
	createItem: {
		label: 'Create',
		action: 'upsertItem',
		name: 'create',
		type: 'button',
	},
	deleteConfig: {
		label: 'Delete',
		action: 'editItem',
		name: 'deleteConfig',
		color: 'danger',
		type: 'button',
	},
}
const sectionsMeta = {
	general: {
		items: [
			{ name: 'status', type: 'field' },
			{ name: 'updatedBy', type: 'field' },
			{ name: 'updatedAt', type: 'field' },
		],
	},
	basic: {
		title: 'Field Details',
		items: [
			{ name: 'name', type: 'field' },
			{ name: 'label', type: 'field' },
			{ name: 'workingHours', type: 'field' },
			{ name: 'status', type: 'field' },
		],
		classes: 'ticket-form caseField',
	},
	headerComponent: {
		component: 'DetailHeader',
	},
}

const screens = {
	// edit: {
	//     title:{label: 'Edit Settings', col: 1, },
	//     items: [{name:'heading', type: 'section'}],
	//     actions: ['editMap', 'cancel', 'save']
	// },
	view: {
		items: [
			{ name: 'headerComponent', type: 'section' },
			{ name: 'queueForm', type: 'section' },
		],
		// classes: 'composite-form',
		title: 'Queue Details',
	},
	create: {
		items: [
			{ name: 'headerComponent', type: 'section' },
			{ name: 'createForm', type: 'section' },
		],
	},
	edit: {
		items: [
			{ name: 'headerComponent', type: 'section' },
			{ name: 'queueForm', type: 'section' },
		],
	},
	// view: {
	//     sections: ['general', 'details'],
	//     actions: ['edit']
	// },
	list: {
		items: [
			{ name: 'label', type: 'link' },
			{ name: 'status', type: 'field' },
			{ name: 'createdBy', type: 'field' },
		],
		// actions: ['view', 'update']
		filter: [
			{
				name: 'status',
				operator: 'ne',
				value: 'Deleted',
			},
		],
		orderBy: ['createdAt desc'],
		multiselect: false,
		layoutType: { excluded: { sort: true, paging: true }, type: ['Grid'] },
	},
}
// const components = { searchList: EntityComponent} // can overrides the components like this if needed
const businessHours = new BusinessHoursEntity({
	fieldsMeta,
	actionsMeta,
	sectionsMeta,
	screens,
	ActionClass: ContentActions,
	name: 'BusinessHours',
	title:'Business Hours',
	methodSchemas,
	collectionSchemas,
	entitySchema,
	bKeys: ['id'],
	entityUrlPaths: { update: 'BusinessHours', getAll: 'BusinessHours', default: 'BusinessHours' },
	fetchDetailByApi: false,
	appComponent: BusinessHoursComponent,
})

const businessHoursListSearchContainer = businessHours.getContainer('ListSearch')
const businessHoursCreateContainer = businessHours.getContainer('AppComponent')
const businessHoursDetailContainer = businessHours.getContainer('AppComponent')
export { businessHoursListSearchContainer, businessHoursCreateContainer, businessHoursDetailContainer }

