import Joi from 'joi-browser'
import PropTypes from 'prop-types'
import React from 'react'
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion'
import { withTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
// import './style.css';
// import { history } from '../../../../../redux/history'
import { Loader } from '../../..'
// import { websiteLiveUrl } from '../../../../../config'
import * as schemas from '../schemas'
// import {Buttons} from './'
import * as expr from 'expression-eval'
import { Action, CardLayout, ChatWidget, ConfrimDialog, DetailHeader, ExpendableDataGrid, Field, LookUpComponent, WorkflowButton } from './'
import './accordian.css'
import SectionDetail from './SectionDetail'
import SearchContainer from './SearchContainer'
// import SearchContainer from '../../../../group/components/SearchContainer'

const styles = () => ({})
export class EntityDetail extends React.Component {
	/**
	 * need the following props
	 *
	 * meta as defined in entity.schemas
	 * idKey: defined in meta
	 * itemId: from params
	 * entityValues: from entity.entityValues
	 *
	 * Able to handle for following...
	 *  Update to the entity
	 *   base properties' update or collection additions / deletions and update of child item
	 *  actions taken on the entity.
	 *    These are actions other than CUD actions.. mapped to specific api calls
	 *
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		if (props.entity) props.entity.uiFieldsMeta = []
		this.state = {
			idKey: props.meta.idKey,
			bKeys: props.meta.bKeys,
			meta: props.meta,
			itemId: (props.meta.idKey || props.meta.bKeys) && (props.itemId || (props.params && props.params.itemId)),
			entityValues: props.entityValues || (props.entity && props.entity.entityValues) || {}, // values that fill the fields
			isLookUp: false,
			gridParentData: [],
			selectedIndexes: [],
			entity: props.entity,
			propsValues: null,
			isPlayer: false,
			isChat: false,
			disableButton: false,
			advanceSearch: props?.meta?.fields?.aadGroupId?.advanceSearch,
			onChangeError: false,
		}
		// this.handleAction.bind(this)
	}

	getRequest() {
		// const { identity: { profile } } = this.props;
		let props = this.props
		let fetchDetailByApi = this.props.meta.fetchDetailByApi
		let entityValues = this.state.entityValues //|| (props.entity && props.entity.entityValues)
		const entityList = this.props.entityList
		let itemData = entityList && entityList.entityName === this.props.moduleName && entityList.itemData
		let { profile, baseUrl, meta } = this.props
		//let profile = identity ? identity.profile : null;
		let tenantId = profile && profile.tenantUid
		let item
		let filter = {}
		if (this.state.idKey || this.state.bKeys)
			this.setState({ itemId: (this.state.idKey || this.state.bKeys) && (props.itemId || (props.params && props.params.itemId)), tenantId })
		let useKey = !!this.state.idKey || !!this.state.bKeys
		let id = props.itemId || (props.params && props.params.itemId)
		if (id != undefined) {
			if (useKey) {
				if (!props.meta.bKeys) filter[props.meta.idKey || 'id'] = id
				else {
					let idTok = id.split('__')
					props.meta.bKeys.forEach((k, i) => {
						if (k === 'tenantId') {
							//TODO:decode to own id or defaultId here.
							filter[k] = idTok[i]
						} else filter[k] = idTok[i]
					})
				}
			} else {
				filter = Object.assign({}, (this.props.routeProps && this.props.routeProps.filter) || this.props.params)
				if (!useKey) delete filter.itemId
			}
			if (!fetchDetailByApi && itemData && itemData.pop) {
				if (useKey) item = itemData.find(i => i[props.meta.idKey] === id)
				else item = itemData[id]
				// if(item && this.props.postActionsHooks) item = this.props.postActionsHooks({actionName: 'getOne', item})
			}
			let selectedFields = null
			if (props && props.meta && props.meta.selectedFields) {
				selectedFields = props.meta.selectedFields
			} else {
				selectedFields = null
			}
			if (!item) this.props.getOne({ tenantId, filter, index: !useKey && id, baseUrl, select: selectedFields, profile, meta, t: this.props.t })
			else this.setState({ entityValues: item })
		} else {
			this.setState({ entityValues: entityValues })
		}
	}
	componentWillMount(props) {
		if (this.state.meta.isUiFieldsMetaEnabled) this.props.getUiFieldsMeta(this.props.t)
		if (window.EmbeddableWidget && window.EmbeddableWidget.el) window.EmbeddableWidget.unmount()
		if (this.state.advanceSearch) {
			this.setState({ advanceSearch: window.location.pathname.includes('/group/create') })
		}
	}

	componentDidMount() {
		let props = this.props
		if (props.itemId || (props.params && props.params.itemId)) {
			this.getRequest()
		}
	}
	async asyncForEach(array, callback) {
		for (let index = 0; index < array.length; index++) {
			await callback(array[index], index, array)
		}
	}

	readFileAsync(file) {
		return new Promise((resolve, reject) => {
			let reader = new FileReader()

			reader.onload = () => {
				resolve(reader.result)
			}

			reader.onerror = reject

			reader.readAsDataURL(file)
		})
	}
	async startReadFiles(name, event) {
		let files = []
		let previewFile = []

		if (event && event.target && event.target.files && event.target.files[0]) {
			let uploadedFile = event.target.files
			let filesData = Object.keys(uploadedFile)
			await this.asyncForEach(filesData, async key => {
				let docName = uploadedFile[key].name
				let docType = uploadedFile[key].type
				let contentBuffer = await this.readFileAsync(uploadedFile[key])

				let docs = {
					doc: contentBuffer,
					docName: docName,
					docType: docType,
					//createdBy: profile.name
				}

				let preview = {
					fileUrl: URL.createObjectURL(uploadedFile[key]),
					fileName: uploadedFile[key].name,
					fileSize: uploadedFile[key].size,
				}
				previewFile.push(preview)
				files.push(docs)
			})
			/**
			 * Need to make this call action and service too as option
			 */
			const fields = this.state.meta.fields
			const field = fields && fields[name]
			if (field && field.action && this.props[field.action]) {
				this.props[field.action](name, files)
			} else {
				let stateFiles = this.state[name]
				const entityValues = this.state.entityValues || (this.props.entity && this.props.entity.entityValues)
				this.setState({
					entityValues: {
						...entityValues,
						[name]: [...stateFiles, ...files],
					},
				})
			}
		}
	}
	handleUploadChange(name, event) {
		this.startReadFiles(name, event)
	}
	handleAction({ actionMeta, actionObj, actionPayload, workflowActionMeta }) {
		let fieldsMeta = this.props.meta.fields
		let entityValues = this.state.entityValues || {}
		Object.keys(fieldsMeta).forEach(f => {
			if (entityValues[f] == undefined && fieldsMeta[f].default != undefined) entityValues[f] = fieldsMeta[f].default
		})
		if (this.state.validationError && !Object.values(this.state.validationError).every(errmessage => errmessage === '' || null)) {
			let errorMessage = Object.values(this.state.validationError)
			toast.error(errorMessage.join(' | '))
		} else {
			if (!actionMeta) return
			const { meta } = this.props
			let apis
			if (meta.apis) {
				meta.screens['create'] &&
					meta.screens['create'].apis &&
					meta.screens['create'].apis.forEach(item => {
						apis = meta.apis[item]
					})
			}
			let actionFn = actionMeta.action
			let modelData = {}
			let idKey = meta.idKey
			if (actionMeta.confirmAction && !actionMeta.callApiAction) {
				modelData.openModal = true
				modelData.confirmActionMessage = actionMeta.confirmActionMessage
				modelData.actionMeta = actionMeta
				modelData.actionObj = actionObj
				modelData.actionPayload = actionPayload
			} else if (actionMeta.action === 'openUrl') {
				if (actionMeta.viewPath) {
					window.open(`/${actionMeta.pathName}/${actionMeta.viewPath}`)
				} else {
					window.open(`/${actionMeta.pathName}/${this.props.params.itemId}`)
				}
			} else {
				if (!this.props[actionFn]) console.error(`Action Fn ${actionFn} is not found.`)
				else
					this.props[actionFn]({
						entityValues,
						id: this.state.idKey && this.state.entityValues[idKey],
						actionObj,
						actionPayload,
						actionMeta,
						workflowActionMeta,
						meta,
						apiMeta: apis,
						profileEntity: this.props.ProfileEntity,
					})
			}
		}
	}
	handleCustomAction({ actionMeta, entityValues, urlPath }) {
		if (!actionMeta) return
		let actionFn = actionMeta.action
		let modelData = {}
		if (actionMeta.confirmAction && !actionMeta.callApiAction) {
			modelData.openModal = true
			modelData.confirmActionMessage = actionMeta.confirmActionMessage
			modelData.actionMeta = actionMeta
		} else {
			if (!this.props[actionFn]) console.error(`Action Fn ${actionFn} is not found.`)
			else this.props[actionFn]({ entityValues: entityValues, path: urlPath, actionMeta })
		}
	}

	handleChange(name, value) {
		let entityValuesRoles = { ...this?.state?.entityValues }
		let entity = entityValuesRoles?.roles?.filter(r => r == 'ASC Admin')
		if(this.state.itemId && entity?.length > 0 && !value?.includes('ASC Admin')){
			this.setState({onChangeError: true})
			toast.warn('Cannot Remove ASC Admin Role.Please Contact Administrator')
			return
		}
		let entityValues = this.state.entityValues || this.props.entityValues || (this.props.entity && this.props.entity.entityValues)
		entityValues[name] = value
		let field = this.state.meta && this.state.meta.fields && this.state.meta.fields[name]
		if (field && field.regexPattern) {
			let pattern = new RegExp(field.regexPattern)
			let passPattern
			if (Array.isArray(value) && value.length >= 1) passPattern = value.every(val => pattern.test(val))
			else passPattern = pattern.test(value)
			if (passPattern === false && value !== '') {
				this.setState({ validationError: { ...this.state.validationError, [name]: field.error } })
			} else {
				this.setState({ validationError: { ...this.state.validationError, [name]: '' } })
			}
		}
		this.setState({
			entityValues,
		})
	}


	handleFieldsChange({ name, value }) {
		const {
			meta,
			meta: { dynamicFields },
		} = this.state
		if (dynamicFields) {
			let dynamicFieldDef = dynamicFields[name]
			const sectionName = dynamicFieldDef.sectionName
			if (dynamicFieldDef && sectionName) {
				const excludeFields = dynamicFieldDef.fields && dynamicFieldDef.fields.map(f => f.name)
				const includeFields = dynamicFieldDef.includesFields && dynamicFieldDef.includesFields[value]
				let sectionDef = includeFields && excludeFields && meta.sections[sectionName]
				let sectionItems = sectionDef && sectionDef.items && sectionDef.items.filter(field => excludeFields.indexOf(field.name) === -1)
				if (sectionItems && sectionItems.pop) sectionItems = [...sectionItems, ...includeFields]
				else {
					sectionItems = []
					sectionItems.push(includeFields)
				}
				sectionDef.items = sectionItems
				meta.sections[sectionName] = sectionDef

				//remove section if exist in excluded
				let excludedSections = dynamicFieldDef.excludeSections && dynamicFieldDef.excludeSections[value]
				meta.sections &&
					Object.keys(meta.sections).forEach(sn => {
						if (excludedSections && excludedSections.length > 0 && excludedSections.indexOf(sn) !== -1) {
							meta.sections[sn].hide = true
						} else {
							meta.sections[sn].hide = false
						}
					})

				this.setState({
					meta: meta,
				})
			}
		}
	}

	storeFieldOptions(name, options) {
		let optionValues = this.state.optionValues || this.props.optionValues || (this.props.entity && this.props.entity.optionValues)
		this.setState({
			optionValues: {
				...optionValues,
				[name]: options,
			},
		})
	}

	componentWillReceiveProps(props) {
		let val = Joi.validate(props, schemas.propsSchema)
		if (val.error) {
			console.error(`${this.props.moduleName || (this.props.entityList ? this.props.entityList.entityName : 'Entity')} has schema error: ${val.error}`)
		} else {
			let view
			let entityValues =
			(props.entity && props.entity.entityValues != undefined && Object.keys(props.entity.entityValues).length !== 0 && props.entity.entityValues) ||
				props.entityValues ||
				this.state.entityValues
			if (props.itemId || (props.params && props.params.itemId)) {
				view = this.props.meta && this.props.meta.screens && this.props.meta.screens.view
			} else {
				view = this.props.meta && this.props.meta.screens && this.props.meta.screens.create
			}

			let linked = this.state.linked || {}
			let linkedCount = this.state.linkedCount || {}
			if (view && view.loadLists && entityValues) {
				view.loadLists.forEach(l => {
					linked[l.targetProp] = this.props.entity[l.itemType]
					linkedCount[l.targetProp] = this.props.entity[l.itemType + '_count']
					if (!linked || !linked[l.targetProp]) {
						this.props.getAllLinked({ itemType: l.itemType, filter: l.filter, orderBy: l.orderBy, top: l.top, t: this.props.t })
						linked[l.targetProp] = true // to prevent repetitive calls when multiple lists are linked to the entity.
					}
				})
			}

			this.setState({
				meta: props.meta,
				entityValues: entityValues,
				linkedLists: linked,
				idKey: props.meta.idKey,
				bKeys: props.meta.bKeys,
				itemId: props.params && props.params.itemId,
			})
		}
	}

	updateLists(linkedLists) {
		let lists = this.state.linkedLists || {}
		lists = Object.assign({}, lists, linkedLists)
		this.setState({ linkedLists: lists })
	}
	itemCellLookUpClick(itemDef) {
		this.setState({
			isLookUp: !this.state.isLookUp,
			lookUpItemDef: itemDef,
		})
	}

	setGridParentData(data, selectedRowIndex, itemDef, isClearParent) {
		let gridParentData = this.state.gridParentData
		if (selectedRowIndex !== -1 && itemDef && itemDef.name && isClearParent) {
			let item = { key: itemDef.name, rowIndex: selectedRowIndex, value: data }
			let itemExist = gridParentData.find(item => item.key === itemDef.name)
			if (itemExist) {
				gridParentData = gridParentData.map(d => {
					if (d.key === itemDef.name) return item
					else return d
				})
				this.setState({ gridParentData: gridParentData })
			} else this.setState({ gridParentData: [...gridParentData, { ...item }] })
		} else if (selectedRowIndex !== -1 && itemDef && itemDef.name && !isClearParent) {
			let newGridParentData = gridParentData.pop && gridParentData.filter((p, i) => p.key !== itemDef.name)
			this.setState({ gridParentData: newGridParentData })
		} else {
			let filterItems = gridParentData.filter(item => item.key !== itemDef.name)
			this.setState({ gridParentData: filterItems })
		}
	}

	handleSelectItems(selectedIndexes) {
		this.setState({
			selectedIndexes: selectedIndexes,
		})
	}

	handleUpdateEntity(entityValues) {
		const { entity } = this.state
		this.setState({
			entity: {
				...entity,
				entityValues,
			},
		})
	}

	handleCardAction(newPropsValues) {
		if (!newPropsValues) return true
		this.setState({
			propsValues: newPropsValues,
		})
	}

	handleTabChange() {
		if (window.EmbeddableWidget && window.EmbeddableWidget.el) window.EmbeddableWidget.unmount()
		this.setState({
			propsValues: null,
		})
	}

	renderItem(item) {
		if (!item) return
		const { fields, actions, sections, gridMeta, workflowAction, accordianMeta } = this.state.meta
		const entityValues = this.state.entityValues || (this.props.entity && this.props.entity.entityValues)
		const optionValues = this.state.optionValues || (this.props.entity && this.props.entity.optionValues)

		const title = this.props.meta && this.props.meta.screens && this.props.meta.screens.view && this.props.meta.screens.view.title
		let itemDef, columns, refData, editDisablePC
		let linkedLists = this.state.linkedLists
		let dependsOn
		switch (item.type) {
			case 'section':
				itemDef = sections[item.name]
				if (itemDef && !itemDef.hide)
					return (
						<div className="row" key={item.name}>
							<SectionDetail
								meta={this.state.meta}
								title={title}
								moduleName={this.props.moduleName}
								key={item.name}
								name={item.name}
								entityValues={entityValues}
								{...this.props}
								linkedLists={linkedLists}
								handleAction={this.handleAction.bind(this)}
								handleChange={this.handleChange.bind(this)}
								handleUploadChange={this.handleUploadChange.bind(this)}
								storeFieldOptions={this.storeFieldOptions.bind(this)}
								optionValues={optionValues}
								handleCardAction={this.handleCardAction.bind(this)}
								propsValues={this.state.propsValues}
								handleTabChange={this.handleTabChange.bind(this)}
								handleCustomAction={this.handleCustomAction.bind(this)}
								handleFieldsChange={this.handleFieldsChange.bind(this)}
							/>
						</div>
					)
				break
			case 'field':
				itemDef = fields[item.name]
				if (itemDef) {
					if (itemDef.dependsOn && itemDef.dependsOn.pop && entityValues) {
						dependsOn = {}
						itemDef.dependsOn.forEach(f => {
							dependsOn[f] = entityValues[f]
						})
					}
					return (
						<div key={item.name}>
							<Field
								{...this.props}
								key={item.name}
								dependsOn={dependsOn}
								name={item.name}
								field={itemDef}
								formValues={entityValues}
								handleChange={this.handleChange.bind(this)}
								handleUploadChange={this.handleUploadChange.bind(this)}
								storeFieldOptions={this.storeFieldOptions.bind(this)}
								optionValues={optionValues}
								handleFieldsChange={this.handleFieldsChange.bind(this)}
							/>
						</div>
					)
				}
				break
			case 'action':
				itemDef = actions[item.name]
				if (itemDef)
					return (
						<div key={item.name}>
							<Action
								actionMeta={itemDef}
								{...this.props}
								linkedLists={linkedLists}
								handleAction={this.handleAction.bind(this)}
								disabled={this.state.disableButton}
							/>
						</div>
					)
				break
			case 'workflowAction':
				itemDef = workflowAction[item.name]
				let possibleStates = []
				if (this.props.entity && this.props.entity.entityValues) {
					possibleStates = this.props.entity.entityValues[itemDef.ref]
				}
				if (itemDef)
					return (
						<div key={item.name}>
							<WorkflowButton workflowActionMeta={itemDef} {...this.props} possibleStates={possibleStates} />
						</div>
					)
				break
			case 'cardList':
				itemDef = gridMeta[item.name]
				columns =
					itemDef &&
					itemDef.items &&
					itemDef.items.map(c => {
						c.shown = true
						return c
					})
				if (itemDef.dataSource.ref) {
					let refs = itemDef.dataSource.ref.split('.')
					refData = refs.reduce((p, c) => {
						return p[c]
					}, entityValues)
				} else refData = entityValues
				if (itemDef && columns)
					return (
						<div key={item.name}>
							
							<CardLayout
								meta={this.props.meta}
								data={refData}
								columns={columns}
								cardPerRow={itemDef.cardPerRow}
								backgroundColor={itemDef.backgroundColor}
								color={itemDef.color}
								itemClick={this.props.itemClick}
								handleListAction={this.props.handleListAction}
								handleCardAction={this.handleCardAction.bind(this)}
								propsValues={this.state.propsValues}
								handleAction={this.handleAction.bind(this)}
								handleCustomAction={this.handleCustomAction.bind(this)}
							/>
						</div>
					)
				break
			case 'grid':
				itemDef = gridMeta[item.name]
				columns = itemDef && itemDef.items
				if (itemDef.dataSource.ref) {
					let refs = itemDef.dataSource.ref.split('.')
					refData = refs.reduce((p, c) => {
						return p[c]
					}, this.props.rowData || entityValues)
				} else refData = entityValues
				let newProps = { ...this.props }
				if (newProps.columns) delete newProps['columns']
				if (newProps.itemDef) delete newProps['itemDef']
				if (newProps.list) delete newProps['list']
				if (newProps?.entity?.entityValues?.id && itemDef?.name && itemDef?.editEval) {
					let data = {
						profile: { preferred_username: newProps.profile.preferred_username },
						program: { createdBy: newProps.entity.entityValues.createdBy },
					}
					let fn = expr.compile(itemDef?.editEval)
					if (fn(data)) {
						editDisablePC = true
						for (let i = 0; i < itemDef?.items.length; i++) {
							if (itemDef?.items[i].type === 'link') {
								newProps.meta.gridMeta[`${itemDef.name}`].items[i].type = 'field'
							} else {
								newProps.meta.gridMeta[`${itemDef.name}`].items[i].disabled = true
							}
						}
					}
				}
				if (itemDef && columns)
					return (
						<div key={item.name}>
							<ExpendableDataGrid
								lookUpMapping={this.state.lookUpMapping}
								meta={this.props.meta}
								list={refData}
								columns={columns}
								fields={fields}
								totalCount={this.props.totalCount}
								importData={this.props.importData}
								handleListAction={this.props.handleListAction}
								entity={this.state.entity}
								itemDef={itemDef}
								itemClick={this.props.itemClick}
								editDisable={editDisablePC ? true : false}
								itemCellLookUpClick={this.itemCellLookUpClick.bind(this)}
								setGridParentData={this.setGridParentData.bind(this)}
								handleSelectItems={this.handleSelectItems.bind(this)}
								gridParentData={this.state.gridParentData}
								handleUpdateEntity={this.handleUpdateEntity.bind(this)}
								{...newProps}
							/>
						</div>
					)
				break
			case 'chat':
				if (window.EmbeddableWidget && window.EmbeddableWidget.el) window.EmbeddableWidget.unmount()
				let params = item.params
				return <ChatWidget key={item.name} {...this.props} params={params} classNames={item.classNames} />
			case 'accordian':
				let accordianDef = accordianMeta[item.name]
				if (accordianDef)
					return (
						<Accordion allowZeroExpanded={true}>
							{accordianDef &&
								accordianDef.items &&
								accordianDef.items.map((i, index) => {
									itemDef = sections[i.name]
									if (itemDef && !itemDef.hide)
										return (
											<AccordionItem key={index}>
												<AccordionItemHeading>
													<AccordionItemButton>{itemDef.title || itemDef.name}</AccordionItemButton>
												</AccordionItemHeading>
												<AccordionItemPanel>
													<div className="row" key={i.name}>
														<SectionDetail
															meta={this.state.meta}
															title={title}
															moduleName={this.props.moduleName}
															key={i.name}
															name={i.name}
															entityValues={entityValues}
															{...this.props}
															linkedLists={linkedLists}
															handleAction={this.handleAction.bind(this)}
															handleChange={this.handleChange.bind(this)}
															handleUploadChange={this.handleUploadChange.bind(this)}
															storeFieldOptions={this.storeFieldOptions.bind(this)}
															optionValues={optionValues}
															handleCardAction={this.handleCardAction.bind(this)}
															propsValues={this.state.propsValues}
															handleTabChange={this.handleTabChange.bind(this)}
															handleCustomAction={this.handleCustomAction.bind(this)}
															handleFieldsChange={this.handleFieldsChange.bind(this)}
														/>
													</div>
												</AccordionItemPanel>
											</AccordionItem>
										)
									else return <div key={index}></div>
								})}
						</Accordion>
					)
				break
			default:
		}
	}
	handleStateReset(stateName) {
		this.setState({ [stateName]: null })
	}

	render() {
		let loading
		const { entity, entityList, meta, t } = this.props
		loading = (entity && entity.loading) || (entityList && entityList.loading) || false
		let { modelData, isLookUp, lookUpItemDef } = this.state
		// let entityValues = this.state.entityValues || (this.props.entity && this.props.entity.entityValues)
		let uiFieldsMeta = entity && entity.uiFieldsMeta
		const sideBarMinimized = this.props.meta && this.props.meta.sideBarMinimized
		if (sideBarMinimized) document.body.classList.remove('sidebar-lg-show')
		else document.body.classList.add('sidebar-lg-show')

		const {
			fields,
			screens: { view, create },
			sections,
		} = meta
		if (uiFieldsMeta && uiFieldsMeta.length > 0) {
			if (sections && sections.basic) {
				uiFieldsMeta.forEach(m => {
					if (!fields[m.fieldName]) {
						let indexToInsert = fields.length
						if (m.sectionConfig) {
							Object.keys(m.sectionConfig).forEach(c => {
								indexToInsert = m.sectionConfig[c].index
								sections.basic.items.splice(indexToInsert, 0, { name: m.fieldName, type: 'field' })
							})
						}
					}
				})
			}
			if (fields) {
				uiFieldsMeta.forEach(m => {
					fields[m.fieldName] = m.fieldProperties
				})
			}
		}

		const itemsByCols = {}
		let screen
		if (this.props.itemId || (this.props.params && this.props.params.itemId)) {
			screen = view
		} else {
			screen = create
		}
		screen &&
			screen.cols &&
			screen.items.forEach(i => {
				if (i.col) {
					itemsByCols[i.col] = itemsByCols[i.col] || []
					itemsByCols[i.col].push(i)
				} else {
					itemsByCols.unassigned = itemsByCols.unassigned || []
					itemsByCols.unassigned.push(i)
				}
			})
		itemsByCols.unassigned = itemsByCols.unassigned || (screen && !screen.cols && screen.items)
		if (screen.cols && itemsByCols.unassigned) return <div>{`Error: Some elements are not assigned to columns ${itemsByCols.unassigned} `}</div>
		else
			return (
				<div>
					<div className="common-heading row">
						<DetailHeader {...this.props} />
					</div>
					<div>
						{modelData && (
							<ConfrimDialog
								openModal={modelData.openModal}
								message={modelData.confirmActionMessage}
								actionMeta={modelData.actionMeta}
								actionObj={modelData.actionObj}
								actionPayload={modelData.actionPayload}
								handleStateReset={this.handleStateReset.bind(this)}
								handleAction={this.handleAction.bind(this)}></ConfrimDialog>
						)}
						{loading && <Loader />}
						{isLookUp && (
							<LookUpComponent
								lookUpMapping={this.state.lookUpMapping}
								toggle={isLookUp}
								fields={fields}
								lookUpItemDef={lookUpItemDef}
								itemCellLookUpClick={this.itemCellLookUpClick.bind(this)}
								gridParentData={this.state.gridParentData}
								handleSelectItems={this.handleSelectItems.bind(this)}
								selectedIndexes={this.state.selectedIndexes}
								handleUpdateEntity={this.handleUpdateEntity.bind(this)}
								{...this.props}
							/>
						)}
					</div>
					<div className="main-section">
						<div className="main-inner">
							<div className="row table-title">
								<div className="col-sm-12">
									<h5>{screen && screen.title && t(screen.title)}</h5>
								</div>
							</div>
							<div className={`${screen.classes ? screen.classes : ''} row`}>
								<div className="col-sm-12">
									{screen && screen.cols && (
										<div className="row">
											{screen.cols.map((c, i) => {
												return (
													<div className="col-sm-{c} section-frame" key={i}>
														{itemsByCols[i + 1] &&
															itemsByCols[i + 1].map(item => {
																return this.renderItem(item)
															})}
													</div>
												)
											})}{' '}
										</div>
									)}
									{itemsByCols &&
										itemsByCols.unassigned &&
										itemsByCols.unassigned.map(item => {
											return this.renderItem(item)
										})}
										{
											this.state.advanceSearch ?<>
											<SearchContainer 
											handleChange={this.handleChange.bind(this)}
											{...this.props}
											{...this.state}/>
											</>:<> 
											</>

										}
								</div>						
							</div>
						</div>
					</div>
				</div>
			)
	}
}
EntityDetail.propTypes = {
	params: PropTypes.object,
	// entityValues: PropTypes.object,
	entity: PropTypes.shape({ entityValues: PropTypes.object, error: PropTypes.string }),

	loading: PropTypes.bool,
}

export default withTranslation()(EntityDetail)
