import { AddIcon } from '@chakra-ui/icons'
import { Button, ChakraProvider, Flex } from '@chakra-ui/react'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { DetailComponent } from '../../common/base/entity/components'
import Attributes from './attributes'
import RoleGroupModal from './role.group.modal'
import { identityServices } from '../../common'
import { session } from '../../../config'

class CreateWithAttributes extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			entityValues: {
				attributes: {},
			},
			isOpen: false,
		}
		this.handleAddDeleteAttrubutes = this.handleAddDeleteAttrubutes.bind(this)
		this.handleModal = this.handleModal.bind(this)
		this.handleUpdateRoles = this.handleUpdateRoles.bind(this)
	}
	async handleAddDeleteAttrubutes({ attributes, attrReq, userAttributes }) {
		if(!userAttributes) {
			const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			url: `${
				this.props.apiUrl || (this.props.tenant && this.props.tenant.apiUrl) || session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
			}/UserAttribute`,
			}
			let response = await identityServices.identityApi.request(requestOptions)
			userAttributes = []
			response && response.data && response.data.forEach(i => i.status == 'Active' && i.validationRule.required == 'Yes' && userAttributes.push(i.label))
			if(userAttributes.length > 0){
				attrReq = true
			}
		}
		let { entityValues } = this.state
		if (!entityValues) entityValues = { attributes: {} }
		this.setState({
			entityValues: {
				...entityValues,
				attributes: Object.assign({}, entityValues.attributes, attributes),
				userAttributes: userAttributes,
				attrReq: attrReq || false,
			},
		})
	}
	componentWillReceiveProps(props) {
		if (props.entity && props.entity.entityValues) {
			this.setState({
				entityValues: props.entity.entityValues,
			})
		}
	}
	handleModal() {
		this.setState({ isOpen: !this.state.isOpen })
	}
	handleUpdateRoles(roles) {
		this.setState({
			entityValues: {
				...this.state.entityValues,
				roles: roles,
			},
		})
		this.handleModal()
	}
	render() {
		const { t } = this.props
		const { entityValues } = this.state
		let newProps = Object.assign({}, { ...this.props })
		if (newProps.entity && newProps.entity.entityValues) newProps.entity.entityValues = entityValues
		return (
			<>
				<ChakraProvider>
					<Button leftIcon={<AddIcon />} colorScheme="blue" variant="solid" position="absolute" mt="21.8%" right="18%" zIndex="1" onClick={this.handleModal}>
						{t('Manage Roles')}
					</Button>
					<Flex>
						{this.state.isOpen && (
							<RoleGroupModal
								isOpen={this.state.isOpen}
								handleModal={this.handleModal}
								handleUpdateRoles={this.handleUpdateRoles}
								selectedRoles={this.state.entityValues && this.state.entityValues.roles ? this.state.entityValues.roles : []}
								tenant={this.props.tenant}
							/>
						)}
					</Flex>
				</ChakraProvider>

				<DetailComponent {...newProps} entityValues={entityValues} />
				<div className="main-section user-attribute">
					<div className="main-inner">
						<Attributes handleAddDeleteAttrubutes={this.handleAddDeleteAttrubutes} attributes={entityValues && entityValues.attributes ? entityValues.attributes : {}} />
					</div>
				</div>
			</>
		)
	}
}
export default withTranslation()(CreateWithAttributes)
