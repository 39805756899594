// import React from 'react';
import { withTranslation } from 'react-i18next';
// import SectionDetail from '../../common/base/entity/components/SectionDetail'
// import { Field, Action, ConfrimDialog, CardLayout, DataGrid, ExpendableDataGrid, WorkflowButton, LookUpComponent } from '../../common/base/entity/components/index'
// import { Loader, alertActions } from '../../common';
import { toast } from 'react-toastify';
import { EntityDetail } from '../../common/base/entity/components/DetailComponent';

/**
 * extended class for expanding and collapsing the properties field on to form.
 */
class UserAttributeDetail extends EntityDetail {
    constructor(props) {
        super(props)  
    }

    componentWillMount() {
        let { meta, params } = this.props
        if (params && params.itemId) {
            meta.fields['key'].readOnly = true
            meta.fields['validationRule'].readOnly = false
        }
        meta.fields['valueLabels'].readOnly = false
        meta.fields['isMultivalue'].readOnly = false
        // let excludeItems = ['isMultivalue', 'valueLabels']
        // var ItemsDef = meta.sections.general && meta.sections.general.items.filter(c=>excludeItems.indexOf(c.name)== -1);
        // if(ItemsDef)
        //  meta.sections.general.items = ItemsDef
        this.setState({meta: meta})
    }
    
    componentWillUnmount() {
        let { meta, params } = this.props
        if (params && params.itemId) {
            meta.fields['key'].readOnly = false
            meta.fields['validationRule'].readOnly = false
        }
    }


    handleChange(name, value) {
        let entityValues = this.state.entityValues || this.props.entityValues || (this.props.entity && this.props.entity.entityValues)
        let { meta, params } = this.props
        entityValues[name] = value
        var ItemsDef;
        if (name == 'validationRule' && value && value.field && value.field == 'Predefined List' || value.field == 'Free Text' || value.field == 'Unique Values') {
            meta.fields['valueLabels'].label = 'Value'
            let includeItems = ['valueLabels', 'isMultivalue']
            
            if(meta.sections.general.items.some(x=>{
                if(x.name=='valueLabels')
                return true 
                else 
                return false
            })) return true;
            includeItems.map((f, i)=>{
                meta.sections.general.items.splice(2+i, 0, {name:f, type: 'field'});
            })
            this.setState({
                meta: meta
            })
        } else if(name == 'validationRule' && value && value.field) {
            meta.fields['valueLabels'].label = ''
            entityValues['valueLabels'] = []
            entityValues['isMultivalue'] = 'No'
            let excludeItems = ['isMultivalue', 'valueLabels']
            ItemsDef = meta.sections.general && meta.sections.general.items.filter(c=>excludeItems.indexOf(c.name)== -1);
            if(ItemsDef)
            meta.sections.general.items = ItemsDef
            this.setState({
                meta: meta
            })
            }
        this.setState({
            entityValues
        })
    }


    handleAction({ actionMeta, actionObj, actionPayload , workflowActionMeta}) {
        if (!actionMeta) return
        const { entity: { entityValues }, meta } = this.props
        let actionFn = actionMeta.action
        let modelData = {}
        let idKey = meta.idKey;
        if((actionFn == 'addNewItem' || actionFn == 'editItem') && this.state.entityValues && this.state.entityValues.validationRule && this.state.entityValues.validationRule.field == 'Predefined List') {
            if(!this.state.entityValues.valueLabels || this.state.entityValues.valueLabels.length < 1) {
                toast.error('Value is required in case of Predefined List.')
                return
            }
        }
        if (actionMeta.confirmAction && !actionMeta.callApiAction) {
            modelData.openModal = true;
            modelData.confirmActionMessage = actionMeta.confirmActionMessage;
            modelData.actionMeta = actionMeta;
            modelData.actionObj = actionObj;
            modelData.actionPayload = actionPayload;
        } else {
            if (!this.props[actionFn]) console.error(`Action Fn ${actionFn} is not found.`)
            else this.props[actionFn]({ entityValues: this.state.entityValues, id: this.state.idKey && this.state.entityValues[idKey], actionObj, actionPayload, actionMeta, workflowActionMeta, meta })
        }
    }



}
export default withTranslation()(UserAttributeDetail);