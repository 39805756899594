import { Entity } from '../common/base/entity/entity'
import { UserAttributeActions } from './actions/user.attributes.actions'
import UserAttributeDetail from './components/user.attributes.detail'
import UserAttributeListsearch from './components/user.attributes.listsearch'
import { collectionSchemas, entitySchema, methodSchemas } from './user.attributes.schema'

class UserAttributesPageEntity extends Entity {
    // override the following if needed.
    // derived actionObj
    // derived Service obj
    // getActionFns if you want to suppress the baseActionobj methods
}


const fieldsMeta = {
	key: {
        label: 'Attribute Key',
        name: 'key',
        type: 'label',
        readOnly: true
    },
	label: {
        label: 'Attribute Name',
        name: 'label',
        type: 'text',
        required: true
    },
    validationRule: {
        label: 'Validation Rule',
        name: 'validationRule',
        type: 'jsonObjFixed',
        valueType: 'jsonList',
        required: true,
        props: [ 
            {
                name: 'field', 
                label: 'Attribute Type', 
                type: 'select',
                options: [{value: 'Free Text', label: 'Free Text', name: 'text'}, /*{value: 'number', label: 'Number', name: 'number'},*/ {value: 'Predefined List', label: 'Predefined List', name: 'choice'},{value: 'Unique Values', label: 'Unique Values', name: 'text'}]
            }, 
            {
                name: 'required',
                label: 'Mandatory in User Profile',
                type: 'select',
                options: [{label: 'Yes', value: 'Yes'}, {label: 'No', value: 'No'}]
            }
        ]
    },
	valueLabels: {
        label: 'Value',
        name: 'valueLabels',
        type: 'jsonText',
        required: true,
    },
	isMultivalue: {
        label: "Allow Multiple Value",
        name: "isMultivalue",
        options: [{label: "Yes", value: 'Yes'}, {label: "No", value: 'No'}],
        type: 'select',
        required: true,
        placeholder: '--Select--'
    },
    status: {
        label: "Status",
        name: 'status',
        options: [{label: 'Active', value: "Active"}, {label: 'Inactive', value: "Inactive"}],
        type: 'select',
        required: true,
        placeholder: '--Select Status--'
    },
    createdBy: {
        label: 'Created By',
        name: 'updatedBy',
        readOnly: true,
        type: 'text'
    },
    createdAt: {
        label: 'Created At',
        name: 'updatedAt',
        readOnly: true,
        type: 'datetime-local'
    },
    updatedBy: {
        label: 'Last Edited By',
        name: 'updatedBy',
        readOnly: true,
        type: 'text'
    },
    updatedAt: {
        label: 'Last Edited At',
        name: 'updatedAt',
        readOnly: true,
        type: 'datetime-local'
    }
}


const actionsMeta = {
    create:{
        label: 'Create',
        action: 'addNewItem',
        name : 'create',
        type: 'button'
    },
    upsert: {
        type: 'button',
        label: 'Update',
        name: 'Update',
        action: 'upsertItem',
    },
    edit: {
        type: 'button',
        label: 'Update',
        action: 'editItem',
        name: 'edit'
    },
    save: {
        label: 'Save',
        name: 'save',
        action: 'addNewItem',
        type: 'button'
    },
    cancel: {
        label: 'Cancel',
        name: 'cancel',
        action: 'cancelEdit'
    },
    update: {
        label: 'Save',
        name: 'update',
        action: 'editItem',
        classes: 'fa fa-pencil fa-lg ',
        type: 'span'
    },
    list: {
        label: 'List All',
        name: 'list',
        action: 'load'
    },
    search: {
        label: 'Search',
        name: 'search',
        action: 'search'
    },
    delete:{
        label:"Delete",
        name:"delete",
        action:'deleteItem',
        backgroundColor:'danger',
        confirmAction: true,
        confirmActionMessage: 'Are you sure, You want to delete?'
    }
}


const sectionsMeta = {
    general: {
        title: 'User Attributes Detail',
        items: [{name:'label', type: 'field'}, {name:'validationRule', type: 'field'}, {name:'valueLabels', type: 'field'}, {name:'isMultivalue', type: 'field'},  {name:'status', type: 'field'}],
        classes:"user-attribute-form"
    },
    basic: {
        title: 'User Attributes Detail',
        items: [{name:'key', type: 'field'}, {name:'label', type: 'field'}, {name:'validationRule', type: 'field'}, {name:'valueLabels', type: 'field'}, {name:'isMultivalue', type: 'field'},  {name:'status', type: 'field'}],
        classes:"user-attribute-form"
    },
    headerComponent: {
        component: 'DetailHeader',
    },
    userAttributeCreateForm: {
        cols: [7,5],
        items:[{name: 'general', type: 'section', col: 1}, 
        {name: 'save', type: 'action', col: 1}],
        classes: 'composite-inner-form'
    },
    userAttributeEditForm: {
        cols: [7,5],
        items:[{name: 'basic', type: 'section', col: 1}, 
        {name: 'update', type: 'action', col: 1}],
        classes: 'composite-inner-form'
    }
    
}



const screens = {
    view: {
        items: [{name:'headerComponent', type: 'section'}, {name: 'userAttributeEditForm', type: 'section'}],
        classes: 'composite-form',
        title: 'User Attributes'
    },
    // edit:{
    //     items: [{name:'headerComponent', type: 'section'}, {name: 'userAttributeForm', type: 'section'}]
    // },
    create:{
        items: [{name:'headerComponent', type: 'section'}, {name: 'userAttributeCreateForm', type: 'section'}]
    },
    // edit: {
    //     items: [{name:'headerComponent', type: 'section'}, {name: 'userAttributeEditForm', type: 'section'}]
    // },
    list: {
        items: [
            {name:'label', type: 'link', row: 1, col: 1}, 
            // {name:'key', type: 'link', row: 1, col: 2}, 
            {name:'isMultivalue', type: 'field', row: 2, col: 1},
            {name:'status', type: 'field', row: 2, col: 2},
            {name:'validationRule', type: 'field', row:3, col: 1},  
            {name:'valueLabels', type: 'field', row: 3, col: 2}, 
            {name:'updatedBy', type: 'field', row: 4, col: 1},
            {name:'updatedAt', type: 'date', row: 4, col: 2 }
        ],
        layoutType: {
            type:['Card'],backgroundColor:'#8E8CD8', cardInRow:3, color:'#fff' 
        },
        apis: [
            'userAttr'
        ],
        orderBy: ['updatedAt desc'],

        // attributes status do have active and inactive so cmted it 
        // filter: [
		// 	{
		// 		name: 'status',
		// 		operator: 'ne',
		// 		value: 'Deleted',
		// 	},
		// ],
        multiselect: false,
    }
}

const apisMeta = {
    userAttr: { response: {targetProp: 'userAttrData', ref: 'data', valueKey: 'data'}, method: 'GET', path: 'role-management/getUserAttribute' }
}


const userAttributes = new UserAttributesPageEntity({ fieldsMeta, actionsMeta, sectionsMeta, ActionClass: UserAttributeActions, screens, apisMeta, name: 'User Attributes', title: 'User Attributes', methodSchemas, collectionSchemas,  entitySchema, entityUrlPaths:{upsert: "UserAttribute/upsert", update: "UserAttribute/update", default: "UserAttribute" }, fetchDetailByApi: true, idKey: "id", components: {Detail: UserAttributeDetail, ListSearch: UserAttributeListsearch} })


const userAttributesSearchContainer = userAttributes.getContainer('ListSearch')
const userAttributesCreateContainer = userAttributes.getContainer('Create')
const userAttributesDetailContainer = userAttributes.getContainer('Detail')
export { userAttributesSearchContainer, userAttributesCreateContainer, userAttributesDetailContainer }

